import { useQuery } from "@urql/vue";
import { MaybeRef } from "vue";
import { client } from "..";
import { graphql } from "../gql";
import {
  Costs_Set_Input,
  Costs_Statuses_Enum,
  ListCostsQueryVariables,
} from "../gql/graphql";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";

export const listCostsQuery = graphql(/* GraphQL */ `
  query listCosts(
    $where: costs_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [costs_order_by!] = {}
    $includeId: Boolean = true
    $includeName: Boolean = false
    $includeDescription: Boolean = false
    $includeAmount: Boolean = false
    $includeAmountUsd: Boolean = false
    $includeAmountUsdWVat: Boolean = false
    $includeAmountWVat: Boolean = false
    $includeVat: Boolean = false
    $includeCurrency: Boolean = false
    $includeInvoiceId: Boolean = false
    $includeBillId: Boolean = false
    $includeToBill: Boolean = false
    $includeType: Boolean = false
    $includeProject: Boolean = false
    $includeInvoice: Boolean = false
    $includeInvoiceDate: Boolean = false
    $includeInvoiceRef: Boolean = false
    $includeSubsidiary: Boolean = false
    $includeSuppliery: Boolean = false
    $includePeriod: Boolean = false
    $includeYear: Boolean = false
    $includeStatus: Boolean = false
    $includeIsDeleted: Boolean = false
    $includeRejectReason: Boolean = false
    $includeApprovedBy: Boolean = false
    $includeCreatedAt: Boolean = false
    $includeCreatedBy: Boolean = false
  ) {
    costs_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    costs(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      id @include(if: $includeId)
      name @include(if: $includeName)
      description @include(if: $includeDescription)
      amount @include(if: $includeAmount)
      amount_usd @include(if: $includeAmountUsd)
      amount_usd_w_vat @include(if: $includeAmountUsdWVat)
      amount_w_vat @include(if: $includeAmountWVat)
      vat @include(if: $includeVat)
      currency @include(if: $includeCurrency)
      invoice_id @include(if: $includeInvoiceId)
      bill_id @include(if: $includeBillId)
      to_bill @include(if: $includeToBill)
      type @include(if: $includeType) {
        id
        name
      }
      project @include(if: $includeProject) {
        id
        name
      }
      invoice @include(if: $includeInvoice) {
        invoice_date @include(if: $includeInvoiceDate)
        invoice_ref @include(if: $includeInvoiceRef)
        subsidiary @include(if: $includeSubsidiary) {
          id
          display_name
        }
        suppliery @include(if: $includeSuppliery) {
          id
          display_name
        }
      }
      period @include(if: $includePeriod)
      year @include(if: $includeYear)
      status @include(if: $includeStatus)
      is_deleted @include(if: $includeIsDeleted)
      reject_reason @include(if: $includeRejectReason)
      approved_by @include(if: $includeApprovedBy) {
        id
        first_name
        last_name
        additional_name
      }
      created_at @include(if: $includeCreatedAt)
      created_by @include(if: $includeCreatedBy) {
        id
        first_name
        last_name
        additional_name
      }
    }
  }
`);

export type ListCostsIncludesValue = IncludesProp<ListCostsQueryVariables>;

export interface ListCostsVariables {
  limit?: MaybeRef<ListCostsQueryVariables["limit"]>;
  offset?: MaybeRef<ListCostsQueryVariables["offset"]>;
  order_by?: MaybeRef<ListCostsQueryVariables["order_by"]>;
  where?: MaybeRef<ListCostsQueryVariables["where"]>;
  includes: MaybeRef<ListCostsIncludesValue>;
}

export function useListCosts(props: ListCostsVariables) {
  return useQuery({
    query: listCostsQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListCostsQueryVariables,
  });
}

export async function listCosts(props: ListCostsVariables) {
  const response = await client
    .query(listCostsQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListCostsQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.costs;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getCostQuery = graphql(/* GraphQL */ `
  query getCost($id: Int!) {
    costs_by_pk(id: $id) {
      id
      name
      description
      amount
      amount_usd
      amount_usd_w_vat
      amount_w_vat
      vat
      currency
      invoice_id
      bill_id
      to_bill
      type {
        id
        name
      }
      project {
        id
        name
      }
      period
      year
      status
      reject_reason
      approved_by {
        id
        first_name
        last_name
        additional_name
      }
      created_at
      created_by {
        id
        first_name
        last_name
        additional_name
      }
    }
  }
`);

export async function getCost(id: number) {
  const response = await client.query(getCostQuery, { id }).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.costs_by_pk ?? null;
}

export const getCostInvoiceIdQuery = graphql(/* GraphQL */ `
  query getCostInvoiceId($id: Int!) {
    costs_by_pk(id: $id) {
      invoice_id
    }
  }
`);

export async function getCostInvoiceId(id: number) {
  const response = await client
    .query(getCostInvoiceIdQuery, { id })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.costs_by_pk?.invoice_id;
}

export const createCostMutation = graphql(/* GraphQL */ `
  mutation createCost($data: costs_insert_input = {}) {
    insert_costs_one(object: $data) {
      id
      name
      description
      amount
      amount_usd
      amount_usd_w_vat
      amount_w_vat
      vat
      currency
      invoice_id
      bill_id
      to_bill
      type {
        id
        name
      }
      project {
        id
        name
      }
      period
      year
      status
      reject_reason
      approved_by {
        id
        first_name
        last_name
        additional_name
      }
      created_at
      created_by {
        id
        first_name
        last_name
        additional_name
      }
    }
  }
`);

export async function createCost(data: Costs_Set_Input) {
  const response = await client
    .mutation(createCostMutation, {
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.insert_costs_one;
  if (!_data) {
    throw new Error("No data received");
  }

  return _data;
}

export const updateCostMutation = graphql(/* GraphQL */ `
  mutation updateCost($id: Int!, $data: costs_set_input!) {
    update_costs_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
      name
      description
      amount
      amount_usd
      amount_usd_w_vat
      amount_w_vat
      vat
      currency
      invoice_id
      bill_id
      to_bill
      type {
        id
        name
      }
      project {
        id
        name
      }
      period
      year
      status
      reject_reason
      approved_by {
        id
        first_name
        last_name
        additional_name
      }
      created_at
      created_by {
        id
        first_name
        last_name
        additional_name
      }
    }
  }
`);

export async function updateCost(id: number, data: Costs_Set_Input) {
  const response = await client
    .mutation(updateCostMutation, {
      id,
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.update_costs_by_pk;
  if (!_data) {
    throw new Error("No data received");
  }

  return _data;
}

export const updateCostsMutation = graphql(/* GraphQL */ `
  mutation updateCosts($ids: [Int!]!, $data: costs_set_input!) {
    update_costs(where: { id: { _in: $ids } }, _set: $data) {
      affected_rows
      returning {
        # Triggers cache update
        __typename
        id
      }
    }
  }
`);

export async function updateCosts(
  ids: number | number[],
  data: Costs_Set_Input
) {
  const response = await client
    .mutation(updateCostsMutation, {
      ids,
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.update_costs;
  if (!_data) {
    throw new Error("No data received");
  }

  return _data;
}
export async function approveCosts(ids: number | number[], userId: string) {
  return updateCosts(ids, {
    status: Costs_Statuses_Enum.Approved,
    approved_by_id: userId,
  });
}
export async function rejectCosts(
  ids: number | number[],
  userId: string,
  rejectionMessage: string
) {
  return updateCosts(ids, {
    status: Costs_Statuses_Enum.Rejected,
    reject_reason: rejectionMessage,
    approved_by_id: userId,
  });
}
export async function reopenCosts(ids: number | number[]) {
  return updateCosts(ids, {
    status: Costs_Statuses_Enum.ToApprove,
    reject_reason: null,
  });
}

export const deleteCostMutation = graphql(/* GraphQL */ `
  mutation deleteCost($id: Int!) {
    update_costs_by_pk(pk_columns: { id: $id }, _set: { is_deleted: true }) {
      id
    }
    delete_costs_by_pk(id: $id) {
      id
    }
  }
`);

export async function deleteCost(id: number) {
  const response = await client
    .mutation(deleteCostMutation, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.delete_costs_by_pk?.id;
  if (!_data) {
    throw new Error("Cost not found");
  }

  return _data;
}
