import { useQuery } from "@urql/vue";
import { client } from "..";
import { graphql } from "../gql";
import { Accounts_Insert_Input, Accounts_Set_Input } from "../types";
import { diff } from "@/utils/set-diff";

/** @deprecated */
export const getAccountByUserId = graphql(/* GraphQL */ `
  query getAccountByUserId($userId: uuid!) {
    accounts(where: { user_id: { _eq: $userId } }, order_by: { id: asc }) {
      id
      name
      role {
        id
        name
      }
      allowed_projects {
        project {
          id
          name
        }
      }
      allowed_subsidiaries {
        subsidiary {
          id
          short_name
        }
      }
      additional_scopes {
        scope
      }
    }
  }
`);

export const getAccountOwnerQuery = graphql(/* GraphQL */ `
  query getAccountOwner($id: uuid!) {
    accounts_by_pk(id: $id) {
      user_id
    }
  }
`);

export async function getAccountOwner(id: string): Promise<string | null> {
  const response = await client
    .query(getAccountOwnerQuery, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const user_id = response.data?.accounts_by_pk?.user_id ?? null;
  return user_id;
}

export const getAccountScopesQuery = graphql(/* GraphQL */ `
  query getAccountScopes($id: uuid!) {
    accounts_scopes(where: { account_id: { _eq: $id } }) {
      id
      scope
    }
  }
`);

export async function getAccountScopes(id: string) {
  const response = await client
    .query(getAccountScopesQuery, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.accounts_scopes ?? [];
}

export const getAccountSubsidiariesQuery = graphql(/* GraphQL */ `
  query getAccountSubsidiaries($id: uuid!) {
    accounts_subsidiaries(where: { account_id: { _eq: $id } }) {
      subsidiary_id
    }
  }
`);

export async function getAccountSubsidiaries(id: string) {
  const response = await client
    .query(getAccountSubsidiariesQuery, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.accounts_subsidiaries ?? [];
}

export const getAccountProjectsQuery = graphql(/* GraphQL */ `
  query getAccountProjects($id: uuid!) {
    accounts_projects(where: { account_id: { _eq: $id } }) {
      project_id
    }
  }
`);

export async function getAccountProjects(id: string) {
  const response = await client
    .query(getAccountProjectsQuery, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.accounts_projects ?? [];
}

export const getUserAccountsQuery = graphql(/* GraphQL */ `
  query getUsersAccount($id: uuid!) {
    accounts(where: { user_id: { _eq: $id } }, order_by: { id: asc }) {
      id
      name
      access_pattern_projects
      role {
        id
        name
        scopes {
          scope
        }
      }
      allowed_projects {
        project {
          id
          name
        }
      }
      allowed_subsidiaries {
        subsidiary {
          id
          legal_name
          short_name
          country
        }
      }
      additional_scopes {
        scope
      }
    }
  }
`);

export function useGetUserAccounts(id: string) {
  return useQuery({
    query: getUserAccountsQuery,
    variables: {
      id,
    },
  });
}

export async function getUserAccounts(id: string) {
  const response = await client
    .query(getUserAccountsQuery, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.accounts;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getUserDefaultAccountQuery = graphql(/* GraphQL */ `
  query getUserDefaultAccount($id: uuid!) {
    default_account(where: { user_id: { _eq: $id } }) {
      account {
        id
        name
        access_pattern_projects
        role {
          id
          name
          scopes {
            scope
          }
        }
        allowed_projects {
          project {
            id
            name
          }
        }
        allowed_subsidiaries {
          subsidiary {
            id
            legal_name
            short_name
            country
          }
        }
        additional_scopes {
          scope
        }
      }
    }
  }
`);

export function useGetUserDefaultAccount(id: string) {
  return useQuery({
    query: getUserDefaultAccountQuery,
    variables: {
      id,
    },
  });
}

export async function getUserDefaultAccount(id: string) {
  const response = await client
    .query(getUserDefaultAccountQuery, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.default_account?.[0]?.account;
  return data;
}

export const updateAccountMutation = graphql(/* GraphQL */ `
  mutation updateAccount($id: uuid!, $data: accounts_set_input!) {
    update_accounts_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`);

export async function updateAccount(
  id: string,
  data: Pick<Accounts_Set_Input, "name" | "role_id" | "access_pattern_projects">
) {
  const response = await client
    .mutation(updateAccountMutation, {
      id,
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const _data = response.data?.update_accounts_by_pk;
  if (!_data) {
    throw new Error(`Account '${id}' doesn't exist`);
  }
  return _data;
}

export const setDefaultAccountMutation = graphql(/* GraphQL */ `
  mutation setDefaultAccount($accountId: uuid!, $userId: uuid!) {
    insert_default_account_one(
      object: { account_id: $accountId, user_id: $userId }
      on_conflict: {
        constraint: default_account_user_id_key
        update_columns: account_id
      }
    ) {
      __typename
    }
  }
`);

export async function setDefaultAccount(accountId: string, userId?: string) {
  if (!userId) {
    const tmpUserId = await getAccountOwner(accountId);
    if (!tmpUserId) {
      throw new Error(
        `Cannot get the account owner. Probably account '${accountId}' doesn't exist`
      );
    }
    userId = tmpUserId;
  }

  const response = await client
    .mutation(setDefaultAccountMutation, {
      accountId,
      userId,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.insert_default_account_one;
  if (!data) {
    throw new Error(`Account '${accountId}' doesn't exist`);
  }
}

export const updateAccountScopesMutation = graphql(/* GraphQL */ `
  mutation updateAccountScopes(
    $add: [accounts_scopes_insert_input!]!
    $remove: [uuid!]!
  ) {
    delete_accounts_scopes(where: { id: { _in: $remove } }) {
      affected_rows
    }
    insert_accounts_scopes(objects: $add) {
      affected_rows
    }
  }
`);

export async function updateAccountScopes(accountId: string, scopes: string[]) {
  const currentScopes = await getAccountScopes(accountId);

  const oldScopesSet = new Set(currentScopes.map(({ scope }) => scope));
  const newScopesSet = new Set(scopes);

  const scopesToDelete = diff(oldScopesSet, newScopesSet);
  const scopesToAdd = diff(newScopesSet, oldScopesSet);

  const response = await client
    .mutation(updateAccountScopesMutation, {
      add: Array.from(scopesToAdd, (scope) => ({
        account_id: accountId,
        scope,
      })),
      remove: currentScopes
        .filter(({ scope }) => scopesToDelete.has(scope))
        .map(({ id }) => id),
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }
}

export const updateAccountSubsidiariesMutation = graphql(/* GraphQL */ `
  mutation updateAccountSubsidiaries(
    $accountId: uuid!
    $add: [accounts_subsidiaries_insert_input!]!
    $remove: [uuid!]!
  ) {
    delete_accounts_subsidiaries(
      where: {
        account_id: { _eq: $accountId }
        subsidiary_id: { _in: $remove }
      }
    ) {
      affected_rows
    }
    insert_accounts_subsidiaries(objects: $add) {
      affected_rows
    }
  }
`);

export async function updateAccountSubsidiaries(
  accountId: string,
  subsidiaries: string[]
) {
  const currentSubsidiaries = await getAccountSubsidiaries(accountId);

  const oldSubsidiariesSet = new Set(
    currentSubsidiaries.map(({ subsidiary_id }) => subsidiary_id)
  );
  const newSubsidiariesSet = new Set(subsidiaries);

  const subsidiariesToDelete = diff(oldSubsidiariesSet, newSubsidiariesSet);
  const subsidiariesToAdd = diff(newSubsidiariesSet, oldSubsidiariesSet);

  const response = await client
    .mutation(updateAccountSubsidiariesMutation, {
      accountId: accountId,
      add: Array.from(subsidiariesToAdd, (subsidiary_id) => ({
        account_id: accountId,
        subsidiary_id,
      })),
      remove: Array.from(subsidiariesToDelete),
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }
}

export const updateAccountProjectsMutation = graphql(/* GraphQL */ `
  mutation updateAccountProjects(
    $accountId: uuid!
    $add: [accounts_projects_insert_input!]!
    $remove: [uuid!]!
  ) {
    delete_accounts_projects(
      where: { account_id: { _eq: $accountId }, project_id: { _in: $remove } }
    ) {
      affected_rows
    }
    insert_accounts_projects(objects: $add) {
      affected_rows
    }
  }
`);

export async function updateAccountProjects(
  accountId: string,
  projects: string[]
) {
  const currentProjects = await getAccountProjects(accountId);

  const oldProjectsSet = new Set(
    currentProjects.map(({ project_id }) => project_id)
  );
  const newProjectsSet = new Set(projects);

  const projectsToDelete = diff(oldProjectsSet, newProjectsSet);
  const projectsToAdd = diff(newProjectsSet, oldProjectsSet);

  const response = await client
    .mutation(updateAccountProjectsMutation, {
      accountId: accountId,
      add: Array.from(projectsToAdd, (project_id) => ({
        account_id: accountId,
        project_id,
      })),
      remove: Array.from(projectsToDelete),
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }
}

export const createAccountMutation = graphql(/* GraphQL */ `
  mutation createAccount($data: accounts_insert_input!) {
    insert_accounts_one(object: $data) {
      id
      name
      role {
        id
        name
      }
      allowed_projects {
        project {
          id
          name
        }
      }
      allowed_subsidiaries {
        subsidiary {
          id
          short_name
        }
      }
      additional_scopes {
        scope
      }
    }
  }
`);

export async function createAccount(data: Accounts_Insert_Input) {
  const response = await client
    .mutation(createAccountMutation, {
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.insert_accounts_one;
  if (!result) {
    throw new Error("No data received");
  }
  return result;
}

export const deleteAccountMutation = graphql(/* GraphQL */ `
  mutation deleteAccount($id: uuid!) {
    delete_accounts_by_pk(id: $id) {
      id
    }
  }
`);

export async function deleteAccount(id: string) {
  const response = await client
    .mutation(deleteAccountMutation, {
      id,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.delete_accounts_by_pk;
  return !!result;
}
