import { useQuery } from "@urql/vue";
import { MaybeRef } from "vue";
import { client } from "..";
import { graphql } from "../gql";
import type { ListSubsidiariesQueryVariables, Subsidiaries_Insert_Input, Subsidiaries_Set_Input } from "../gql/graphql";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";

export const listSubsidiariesQuery = graphql(/* GraphQL */ `
  query listSubsidiaries(
    $where: subsidiaries_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [subsidiaries_order_by!] = {}
    $includeId: Boolean = true
    $includeLegalName: Boolean = false
    $includeShortName: Boolean = false
    $includeCountry: Boolean = false
    $includeCreatedAt: Boolean = false
  ) {
    subsidiaries_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    subsidiaries(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id @include(if: $includeId)
      legal_name @include(if: $includeLegalName)
      short_name @include(if: $includeShortName)
      country @include(if: $includeCountry)
      created_at @include(if: $includeCreatedAt)
    }
  }
`);

export type ListSubsidiariesIncludesValue =
  IncludesProp<ListSubsidiariesQueryVariables>;

export interface ListSubsidiariesVariables {
  limit?: MaybeRef<ListSubsidiariesQueryVariables["limit"]>;
  offset?: MaybeRef<ListSubsidiariesQueryVariables["offset"]>;
  order_by?: MaybeRef<ListSubsidiariesQueryVariables["order_by"]>;
  where?: MaybeRef<ListSubsidiariesQueryVariables["where"]>;
  includes: MaybeRef<ListSubsidiariesIncludesValue>;
}

export function useListSubsidiaries(props: ListSubsidiariesVariables) {
  return useQuery({
    query: listSubsidiariesQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListSubsidiariesQueryVariables,
  });
}

export async function listSubsidiaries(props: ListSubsidiariesVariables) {
  const response = await client
    .query(listSubsidiariesQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListSubsidiariesQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.subsidiaries;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getSubsidiaryQuery = graphql(/* GraphQL */ `
  query getSubsidiary($id: uuid!) {
    subsidiaries_by_pk(id: $id) {
      id
      legal_name
      short_name
      country
      created_at
    }
  }
`);

export async function getSubsidiary(id: string) {
  const response = await client.query(getSubsidiaryQuery, { id }).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.subsidiaries_by_pk;
}

export const getSubsidiariesQuery = graphql(/* GraphQL */ `
  query getSubsidiaries {
    subsidiaries {
      id
      legal_name
      short_name
      country
      created_at
    }
  }
`);

export function useGetSubsidiaries() {
  return useQuery({
    query: getSubsidiariesQuery,
  });
}

export async function getSubsidiaries() {
  const response = await client.query(getSubsidiariesQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.subsidiaries;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const findSubsidiaryByNameQuery = graphql(/* GraphQL */ `
  query findSubsidiaryByName($name: String!) {
    subsidiaries(where: {legal_name: {_ilike: $name}}, limit: 1) {
      country
      id
      legal_name
      short_name
    }
  }
`);

export async function findSubsidiaryByName(name: string) {
  const response = await client.query(findSubsidiaryByNameQuery, { name }).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.subsidiaries[0];
}

export const createSubsidiaryMutation = graphql(/* GraphQL */ `
  mutation createSubsidiary($data: subsidiaries_insert_input!) {
    insert_subsidiaries_one(object: $data) {
      country
      created_at
      id
      legal_name
      short_name
    }
  }
`);

export async function createSubsidiary(data: Subsidiaries_Insert_Input) {
  const response = await client
    .mutation(createSubsidiaryMutation, {
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.insert_subsidiaries_one;
  if (!result) {
    throw new Error("No data received");
  }

  return result;
}

export const updateSubsidiaryMutation = graphql(/* GraphQL */ `
  mutation updateSubsidiary($id: uuid!, $data: subsidiaries_set_input!) {
    update_subsidiaries_by_pk(pk_columns: { id: $id }, _set: $data) {
      country
      created_at
      id
      legal_name
      short_name
    }
  }
`);

export async function updateSubsidiary(id: string, data: Subsidiaries_Set_Input) {
  const response = await client
    .mutation(updateSubsidiaryMutation, {
      id,
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.update_subsidiaries_by_pk;
  if (!result) {
    throw new Error("No data received");
  }

  return result;
}

export const deleteSubsidiaryMutation = graphql(/* GraphQL */ `
  mutation deleteSubsidiary($id: uuid!) {
    delete_subsidiaries_by_pk(id: $id) {
      country
      created_at
      id
      legal_name
      short_name
    }
  }
`);

export async function deleteSubsidiary(id: string) {
  const response = await client
    .mutation(deleteSubsidiaryMutation, {
      id,
    })
    .toPromise();

  if (response.error) {
    const isConstraintViolation = response.error.graphQLErrors.some(
      (error) => error.extensions?.code === "constraint-violation"
    );
    if (isConstraintViolation) {
      throw new Error("Other entities depend on it.");
    }
    throw new Error(response.error.toString());
  }

  const result = response.data?.delete_subsidiaries_by_pk;
  if (!result) {
    throw new Error("No data received");
  }

  return result;
}
