import { useQuery } from "@urql/vue";
import { MaybeRef } from "vue";
import { client } from "..";
import { graphql } from "../gql";
import type {
  Clients_Insert_Input,
  Clients_Set_Input,
  ListClientsQueryVariables,
} from "../gql/graphql";
import { IncludesProp, expandIncludes } from "../utils/includes-prop";

export const listClientsQuery = graphql(/* GraphQL */ `
  query listClients(
    $where: clients_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [clients_order_by!] = {}
    $includeId: Boolean = true
    $includeLegalName: Boolean = false
    $includeShortName: Boolean = false
    $includeCountry: Boolean = false
    $includeCreatedAt: Boolean = false
  ) {
    clients_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    clients(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id @include(if: $includeId)
      legal_name @include(if: $includeLegalName)
      short_name @include(if: $includeShortName)
      country @include(if: $includeCountry)
      created_at @include(if: $includeCreatedAt)
    }
  }
`);

export type ListClientsIncludesValue = IncludesProp<ListClientsQueryVariables>;

export interface ListClientsVariables {
  limit?: MaybeRef<ListClientsQueryVariables["limit"]>;
  offset?: MaybeRef<ListClientsQueryVariables["offset"]>;
  order_by?: MaybeRef<ListClientsQueryVariables["order_by"]>;
  where?: MaybeRef<ListClientsQueryVariables["where"]>;
  includes: MaybeRef<ListClientsIncludesValue>;
}

export function useListClients(props: ListClientsVariables) {
  return useQuery({
    query: listClientsQuery,
    variables: {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListClientsQueryVariables,
  });
}

export async function listClients(props: ListClientsVariables) {
  const response = await client
    .query(listClientsQuery, {
      ...expandIncludes(props.includes),
      limit: props.limit,
      offset: props.offset,
      order_by: props.order_by,
      where: props.where,
      // Hack: have to override the type. See known issues in README
    } as ListClientsQueryVariables)
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.clients;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const getClientQuery = graphql(/* GraphQL */ `
  query getClient($id: uuid!) {
    clients_by_pk(id: $id) {
      id
      legal_name
      short_name
      country
      created_at
    }
  }
`);

export async function getClient(id: string) {
  const response = await client.query(getClientQuery, { id }).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.clients_by_pk;
}

export const getClientsQuery = graphql(/* GraphQL */ `
  query getClients {
    clients {
      id
      legal_name
      short_name
      country
      created_at
    }
  }
`);

export function useGetClients() {
  return useQuery({
    query: getClientsQuery,
  });
}

export async function getClients() {
  const response = await client.query(getClientsQuery, {}).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const data = response.data?.clients;
  if (!data) {
    throw new Error("No data received");
  }

  return data;
}

export const findClientByNameQuery = graphql(/* GraphQL */ `
  query findClientByName($name: String!) {
    clients(where: {legal_name: {_ilike: $name}}, limit: 1) {
      country
      id
      legal_name
      short_name
    }
  }
`);

export async function findClientByName(name: string) {
  const response = await client.query(findClientByNameQuery, { name }).toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  return response.data?.clients[0];
}

export const createClientMutation = graphql(/* GraphQL */ `
  mutation createClient($data: clients_insert_input!) {
    insert_clients_one(object: $data) {
      country
      created_at
      id
      legal_name
      short_name
    }
  }
`);

export async function createClient(data: Clients_Insert_Input) {
  const response = await client
    .mutation(createClientMutation, {
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.insert_clients_one;
  if (!result) {
    throw new Error("No data received");
  }

  return result;
}

export const updateClientMutation = graphql(/* GraphQL */ `
  mutation updateClient($id: uuid!, $data: clients_set_input!) {
    update_clients_by_pk(pk_columns: { id: $id }, _set: $data) {
      country
      created_at
      id
      legal_name
      short_name
    }
  }
`);

export async function updateClient(id: string, data: Clients_Set_Input) {
  const response = await client
    .mutation(updateClientMutation, {
      id,
      data,
    })
    .toPromise();

  if (response.error) {
    throw new Error(response.error.toString());
  }

  const result = response.data?.update_clients_by_pk;
  if (!result) {
    throw new Error("No data received");
  }

  return result;
}

export const deleteClientMutation = graphql(/* GraphQL */ `
  mutation deleteClient($id: uuid!) {
    delete_clients_by_pk(id: $id) {
      country
      created_at
      id
      legal_name
      short_name
    }
  }
`);

export async function deleteClient(id: string) {
  const response = await client
    .mutation(deleteClientMutation, {
      id,
    })
    .toPromise();

  if (response.error) {
    const isConstraintViolation = response.error.graphQLErrors.some(
      (error) => error.extensions?.code === "constraint-violation"
    );
    if (isConstraintViolation) {
      throw new Error("Other entities depend on it.");
    }
    throw new Error(response.error.toString());
  }

  const result = response.data?.delete_clients_by_pk;
  if (!result) {
    throw new Error("No data received");
  }

  return result;
}
